.modal-content {
  color: #ffffff;
  border: 1px solid #daa629;
  --cui-modal-bg: #02121d;
  --cui-modal-border-color: rgb(1 127 138);
}
.modal-content .modal-header {
  align-items: flex-start;
  border: none;
  padding: 20px;
}
.modal-content .modal-body {
  padding: 10px;
}
.modal-content button.btn-close {
  outline: none;
  box-shadow: none !important;
  opacity: 1;
  filter: invert(1);
}
.modal-content .modal-title {
  font-size: 24px;
  margin-bottom: 0px;
  color: #ffffff;
  text-shadow: 0.3px 0.3px 0.3px #111;
}
.modal-content .modal-title p {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  margin: 10px 0px 0px;
  text-shadow: none;
}

.popupbtngrp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.popupbtngrp .btngrp {
  flex-basis: calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  margin: 0px 10px 20px;
}
.popupbtngrp button,
.popupbtngrp a {
  border: 1px solid #092e3b;
  color: #ffffff;
  font-size: 16px;
  padding: 20px 8px;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 1;
  display: block;
  text-decoration: none;
  box-shadow: inset 0px 0px 20px rgb(9, 46, 59);
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
  background: #02121d;
}
.popupbtngrp button img,
.popupbtngrp a img {
  display: block;
  margin: 0 auto 15px;
  height: 45px;
  width: auto;
}
.popupbtngrp button span,
.popupbtngrp a span {
  display: block;
}

@media (max-width: 567px) {
  .popupbtngrp button,
  .popupbtngrp a {
    font-size: 14px;
    padding: 15px 6px;
  }
}/*# sourceMappingURL=ConnectWallet.css.map */