*,
*::before,
*::after {
    box-sizing: border-box;
}

.bg-themedark {
    background-color: #ffffff;
}

// @font-face {
//     font-family: 'Digital-7 Mono';
//     src: url('../Fonts/Digital-7Mono.eot');
//     src: url('../Fonts/Digital-7Mono.eot?#iefix') format('embedded-opentype'),
//         url('../Fonts/Digital-7Mono.woff2') format('woff2'),
//         url('../Fonts/Digital-7Mono.woff') format('woff'),
//         url('../Fonts/Digital-7Mono.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }
@font-face {
    font-family: 'BudmoJiggler-Regular';
    src: url('../Fonts/BudmoJiggler-Regular.eot');
    src: url('../Fonts/BudmoJiggler-Regular.eot?#iefix') format('embedded-opentype'),
        url('../Fonts/BudmoJiggler-Regular.woff2') format('woff2'),
        url('../Fonts/BudmoJiggler-Regular.woff') format('woff'),
        url('../Fonts/BudmoJiggler-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

body {
    margin: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    color: white;
    text-align: left;
    background: #141617;
    word-break: break-word;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;

}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #646464;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #505050;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'BudmoJiggler-Regular', sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

#root {
    overflow: hidden;
}

input {
    outline: none;
    box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.bgVideoBody {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    z-index: -1;
    opacity: 0.1;
    mix-blend-mode: difference;

    video {
        object-fit: cover;
    }
}

.bgBody {
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
}

.coinbanervideo svg {
    transform: scale(1);
    mix-blend-mode: lighten;
}

.currentSaleVideo svg {
    transform: translate3d(0px, 0px, 0px) scale(1.5) !important;
    mix-blend-mode: lighten;
}

.bg-themedark {
    background-color: #ffffff;
}

.bg-themelight {
    background-color: #ffffff;
}

.oc_row {
    display: flex;
    flex-wrap: wrap;
}

.align-items-flex-end {
    align-items: flex-end;
}

.justify-content-flex-end {
    justify-content: flex-end;
}

.align-items-flex-start {
    align-items: flex-start;
}

.justify-content-flex-start {
    justify-content: flex-start;
}

.oc_col6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 10px;
}

.oc_container {
    width: 1320px;
    max-width: calc(100% - 10vw);
    margin-left: auto;
    margin-right: auto;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.title {
    text-align: center;
    padding: 0;
    margin: 0px 0px 40px;
    border: none;

    h2 {
        text-align: center;
        display: block;
        margin: 0px;
        color: #ffffff;
        font-size: 28px;
        text-transform: capitalize;
    }
}

.btnsalebnr {
    text-align: center;
    background-color: transparent;
    color: #000000 !important;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    position: relative;
    display: block;
    margin: 30px 0px;
    max-width: 140px;
    font-size: 20px;

    &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
        border-radius: 6px;
        z-index: -1;
        animation: animatedgradient 3s ease alternate infinite;
        background-size: 300% 300%;
    }
}

.buttonGrp {
    text-align: left;
    margin-top: 15px;
    margin-left: -7.5px;
    margin-right: -7.5px;

}

.oc_btn {
    background-color: #ffffff;
    padding: 15px 20px;
    border-radius: 5px;
    margin: 15px 7.5px 15px;
    min-width: 180px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;

    &:hover {
        opacity: 0.8;
    }

    &:nth-last-child(1) {
        background-color: transparent;
        color: #ffffff;
        border: 1px solid #ffffff;
        font-weight: 400;
    }

    &.copyRefferalCode {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;

        img {
            height: auto;
            width: auto;
            margin-left: 10px;
            max-height: 19px;
            max-width: 20px;
        }
    }
}

// Start
// .borderboxmain {
//     display: block;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: calc(100%);
//     min-height: 100vh;
//     z-index: -1;

//     img {
//         width: calc(100vw - 3vw);
//         height: auto;
//         display: block;
//         position: absolute;
//         object-fit: contain;

//         &.linetopleft {
//             top: 1vw;
//             left: 1vw;
//             object-position: left;

//         }

//         &.linebottomright {
//             bottom: 1vw;
//             right: 1vw;
//             object-position: right;

//         }
//     }
// }

.main {
    // padding: 200px 5vw 5vw;
    position: relative;
    // min-height: 1000px;
}

.tooltipIcon {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-weight: bold;
    border: none;
    margin-left: 6px;
    font-size: 16px;
    line-height: 1;

}

// Media Query Css

@import 'media';