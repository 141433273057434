@keyframes round {
    100% {
        transform: rotateY(-360deg);
    }
}

.projects {
    margin: 0 0 0px;

    .listcurrent {
        .imageBox {
            text-align: center;

            img {
                max-width: 450px;
                width: 100%;
                filter: drop-shadow(2px 4px 6px black);
            }
        }

        // .listBoxInner {
        //     margin-right: 0px;
        // }
    }

    .countBox {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 10px;

        .countinbox {
            // border: 1px solid #ffffff;
            // border-radius: 15px 0px 15px 0px;
            padding: 15px;
            height: 100%;
            position: relative;
        }

        svg {
            height: 50px;
            display: block;
            margin-bottom: 15px;
            margin-inline: auto;

            path {
                fill: #ffffff;
            }
        }

        h2 {
            font-size: 32px;
        }

        h3 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0px;
            color: #ffffff;
        }

        strong {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0px;
            color: #ffffff;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            margin: 0px 0px 8px;
        }
    }

    .col-sm-6 {
        padding: 15px;
    }


    .alice-carousel {
        .listBoxInner {
            border: 1px solid #ff0000;
        }
    }

    .listBoxInner {
        border-radius: 10px;
        padding: 20px;
        margin-right: 20px;
        background-color: #18141d;
        transition: all 0.5s;
        position: relative;
        // &::before {
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     content: "";
        //     mix-blend-mode: difference;
        //     opacity: .5;
        //     position: absolute; 
        //     background-image: url(../../Assets/images/topBorder.svg);
        //     background-position: 0 0;
        //     right: -2px;
        //     top: -4px;
        //     height: 60px;
        //     width: 60px;
        //     transform: scaleX(-1);

        // }
        // &::after {
        //     background-image: url('../../Assets/images/bottomBorder.svg');
        //     background-position: 100% 100%;
        //     bottom: 0px;
        //     height: 50px;
        //     left: 0px;
        //     width: 70px;
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     content: "";
        //     mix-blend-mode: difference;
        //     opacity: .5;
        //     position: absolute;
        //     transform: scaleX(-1);
        // }

        // &:hover {
        //     transform: translateY(-10px);
        // }
        &::before {
            content: "";
            position: absolute;
            top: -4px;
            left: -4px;
            height: calc(100% + 8px);
            width: calc(100% + 8px);
            background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
            border-radius: 12px;
            z-index: -1;
            animation: animatedgradient 3s ease alternate infinite;
            background-size: 300% 300%;
        }

        .profilePicBox {
            width: fit-content;
            //   border: 1px solid #ffffff;
            margin-top: -50px;
            //   background: #02121d;
            //   border-radius: 0px 10px 0px 10px;
            padding: 0px
        }

        .profileInfo {
            align-items: center;
            justify-content: space-between;
            margin: 0px;
        }

        .profilePic {
            flex: 0 0 56px;
            max-width: 56px;
            height: 56px;
            border-radius: 50%;
            padding: 0px;
            // margin: 8px;
            filter: drop-shadow(2px 4px 6px black);
            // animation: round 2s linear 1;


        }

        .profileInfo {
            ul {
                padding: 0px;
                margin: 0px;
                list-style: none;
                text-align: right;

                li {
                    display: none;
                    text-align: center;
                    padding: 5px 10px;

                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: 500;

                    span {
                        width: 8px;
                        height: 8px;
                        border-radius: 50px;
                        display: inline-block;
                        margin-right: 4px;
                    }

                    &.upcoming {
                        background-color: #001f1f;
                        color: #d29813;

                        span {
                            background-color: #d29813;
                        }
                    }

                    &.saleLive {
                        background-color: #d1fae5;
                        color: #10b981;

                        span {
                            background-color: #10b981;

                        }
                    }

                    &.saleEnded {
                        background-color: #02121d;
                        color: #ffffff;

                        span {
                            background-color: #ff3465;
                        }
                    }

                    &.active {
                        display: inline-block;
                    }
                }
            }
        }

        h2 {
            font-size: 22px;
            font-weight: 600;
            margin: 0;
            display: block;
        }

        .subtitle {
            font-size: 15px;
            font-weight: 600;
            color: #888;
            margin: 0px;
            display: block;
        }

        h3 {
            font-size: 15px;
            font-weight: 600;
            margin: 0;
            display: block;
        }

        strong {
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
            margin: 5px 0px 10px;
            display: block;
        }

        .progressBar {
            margin: 3px 0 0px;
            font-size: 15px;
        }

        .progresLine {
            position: relative;
            display: inline-block;
            width: 100%;
            overflow: hidden;
            background-color: rgba(108, 108, 108, 0.35);
            border-radius: 100px;
            height: 8px;
            margin: 20px 0px;


            span {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                // background: rgb(72, 199, 116);
                background-color: #ffffff;
                border-radius: 3px;

                &::before {
                    content: "";
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    height: calc(100% + 8px);
                    width: calc(100% + 8px);
                    background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
                    border-radius: 6px;
                    z-index: -1;
                    animation: animatedgradient 3s ease alternate infinite;
                    background-size: 300% 300%;
                    z-index: 2;
                }

            }
        }

        .tableBox {
            p {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 15px;
                font-weight: 400;
                color: #ffffff;
                margin: 0 0 5px;

                span {
                    font-size: 15px;
                    color: #888;
                    font-weight: 600;
                }

                &.ttl {
                    color: #888;
                    font-size: 13px;
                    margin: 0 0 15px;
                    font-weight: 600;

                    span {
                        font-size: 13px;
                    }
                }
            }
        }

        .dividerLine {
            display: block;
            margin-bottom: 20px;
            width: 100%;
            height: 1px;
            background-color: #ffffff5e;
            margin-top: 5px;
        }

        .saleTineView {
            align-items: center;
            margin: 0px;
            z-index: 2;
            position: relative;

            // &:hover {
            //     .btnView {
            //         background-color: #ffffff;
            //         color: #001f1f;
            //         font-weight: 600;
            //     }
            // }

            .timesSale {
                margin: 0px;
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(100% - 105px);
                max-width: calc(100% - 105px);
                margin-right: 15px;
                padding: 0px;
                font-size: 14px;

                span {
                    color: #ffffff;
                    display: block;
                    font-size: 14px;
                    margin-top: 4px;
                    letter-spacing: 1.2px;
                    font-weight: 600;
                }
            }

            .btnView {
                flex: 0 0 90px;
                max-width: 90px;
                text-align: center;
                background-color: transparent;
                color: #000000;
                text-decoration: none;
                padding: 10px 5px;
                border-radius: 5px;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    height: calc(100% + 4px);
                    width: calc(100% + 4px);
                    background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
                    border-radius: 6px;
                    z-index: -1;
                    animation: animatedgradient 3s ease alternate infinite;
                    background-size: 300% 300%;
                }
            }

            .react-countdown {
                padding: 0px;
                margin: 0px;
                list-style: none;

                li {
                    display: inline-block;
                    margin-right: 4px;

                    &::after {
                        top: 5px;
                        font-size: 14px;
                        right: -7px;
                    }
                }

                .digit {
                    color: #ffffff;
                    margin: 5px 0px 10px;
                    display: block;
                    font-size: 14px;
                    font-weight: bold;
                }

                .text {
                    display: none;
                }
            }
        }


        &:hover {
            // border-color: #e02de2;

            .profilePic {
                animation: round 2s linear 1;
            }

            // .saleTineView .btnView {
            //     background-color: #ffffff;
            //     color: #001f1f;
            //     font-weight: 600;
            // }

            &::before {
                opacity: 1;
                mix-blend-mode: unset;
            }

            &::after {
                opacity: 1;
                mix-blend-mode: unset;

            }


        }

        &.active {
            animation: blinkOcPublicBrn 1.5s infinite;
            transition: all 0.2s ease-in-out;
            box-shadow: none;
            text-shadow: none;

            @keyframes blinkOcPublicBrn {
                0% {
                    border-color: #ffffff;
                    opacity: 1;
                    box-shadow: none;
                    text-shadow: none;
                }

                49% {
                    border-color: #ffffff;
                    color: #ffffff;
                    text-shadow:
                        -0.2rem -0.2rem 1rem #ffffff,
                        0.2rem 0.2rem 1rem #ffffff,
                        0 0 2rem #ffffff,
                        0 0 4rem #ffffff,
                        0 0 6rem #ffffff,
                        0 0 8rem #ffffff,
                        0 0 10rem #ffffff;
                    box-shadow:
                        0 0 .1rem #ffffff,
                        inset 0 0 .1rem #ffffff,
                        0 0 2rem #ffffff,
                        inset 0 0 0rem #ffffff,
                        0 0 1rem #ffffff,
                        inset 0 0 1rem #ffffff;

                }

                50% {
                    border-color: #ffffff;
                    opacity: 1;
                    box-shadow: none;
                    text-shadow: none;

                }
            }

            .infoBox {
                text-shadow: none;
            }
        }
    }

    .alice-carousel__dots-item:not(.__custom) {
        background-color: #ffffff;
        border-radius: 2px;
        height: 15px;
        transform: skew(-10deg);
        width: 15px;
        opacity: 0.3;
    }

    .alice-carousel__dots-item:not(.__custom):hover,
    .alice-carousel__dots-item:not(.__custom).__active {
        background-color: #ffffff;
        opacity: 1;
    }

}

@media only screen and (max-width: 575px) {
    .projects .countBox .countinbox {
        margin-bottom: 20px;
        height: auto;
    }
}