.buyOc {
  margin: 0 0 60px;
}
.buyOc .buyOcbox {
  background-color: #17151c;
  padding: 30px;
  border-radius: 0px;
  height: 100%;
  position: relative;
}
.buyOc .buyOcbox::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: calc(100% + 6px);
  width: calc(100% + 6px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.buyOc .buyOcbox .topttl {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}
.buyOc .buyOcbox .topttl span {
  font-size: 14px;
}
.buyOc .buyOcbox input {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.161);
  border: none;
  border-radius: 5px;
  margin-bottom: 8px;
  font-size: 20px;
  color: #ffffff;
  border-radius: 6px 0px 0px 6px;
}
.buyOc .buyOcbox .maxBtn {
  background-color: rgba(255, 255, 255, 0.161);
  font-size: 20px;
  color: #ffffff;
  border: none;
  padding: 15px;
  border-radius: 0px 6px 6px 0px;
  width: 100%;
  text-align: right;
}
.buyOc .buyOcbox .oc_btn {
  min-width: auto;
}
.buyOc .buttonGrp {
  text-align: center;
}/*# sourceMappingURL=Claim.css.map */