
.clock-logo img{
    width: 100%;
  }
  
  .clock-logo {
    max-width: 115px;
    margin: 0 auto;
  }
  .clock-title h3{
    font-size: 30px;
    // font-family: 'Digital-7 Mono';


    text-align: center;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 8px;
    font-weight: 300;
    margin-top: 5px;
  }
  .spider-bg {
  object-fit: cover;
  position: absolute;
  z-index: -1;
  transform: translateX(-50%);
  left: calc(50%);
  max-width: 100%;
  bottom: 0;
  height: 100%;
  }
  
  /* flip-timer-css-start */
  /* body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #EEE;
  } */
  .main-clock-area{
    position: relative;
    width: max-content;
    margin:30px auto;
  }
  .flip-clock {
    text-align: center;
    perspective: 400px;
    margin: 10px auto;
  }
  .flip-clock *,
  .flip-clock *:before,
  .flip-clock *:after {
    box-sizing: border-box;
  }
  /* .flip-clock__card.card::after{
    content: ':';
    position: absolute;
    color: #FFF;
    position: absolute;
    right: -22px;
    font-size: 50px;
    transform: translateY(-50%);
  }
  .flip-clock__piece:nth-last-child(1) .flip-clock__card.card::after {
    display: none;
  } */
  .flip-clock__piece {
    display: inline-block;
    margin: 0 9px;
  }
  .flip-clock__slot {
    font-size: 20px;
    color: #fff;
    // font-family: 'Digital-7 Mono';
    text-transform: uppercase;
    margin-top: 5px;
    font-weight: 400;
  
  }
  .card {
    display: block;
    position: relative;
    padding-bottom: 0.72em;
    font-size: 4.5vw;
    line-height: 0.95;
    margin-bottom: 10px;
    bottom: 0%;
    // font-family: 'Digital-7 Mono';


    font-weight: 400;
    border-radius: 0em;
    // border: 2px solid #125d61;
    letter-spacing: 5px;
  }
  .card__top,
  .card__bottom,
  .card__back::before,
  .card__back::after {
    display: block;
    height: 0.72em;
    color: #ffffff;
    background-image: linear-gradient(to right, #02121d, #0a1f2f, #02121d);
    padding: 0.25em 0.25em;
    backface-visiblity: hidden;
    transform-style: preserve-3d;
    width: 1.9em;
    font-weight: 400;
    /* border-radius: 0.1em .1em 0em 0em; */
  }
  .card__bottom {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to right, #02121d, #0a1f2f, #02121d);
    overflow: hidden;
    /* border-radius: 0em 0em 0.1em 0.1em; */
  }
  .card::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 38px;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 100%;
    height: 0px;
    background-image: linear-gradient(to right, #000000, #181313, #000000);
  }
  .card__bottom::after {
    display: block;
    margin-top: -0.72em;
  }
  .card__back::before,
  .card__bottom::after {
    content: attr(data-value);
  }
  .card__back {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0%;
    pointer-events: none;
  }
  
  /* .card:nth-last-child().card__back::after{
    content: none;
  } */
  .card__back::before {
    position: relative;
    z-index: -1;
    overflow: hidden;
  }
  .flip .card__back::before {
    animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
    animation-fill-mode: both;
    transform-origin: center bottom;
  }
  .flip .card__back .card__bottom {
    transform-origin: center top;
    animation-fill-mode: both;
    animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
  }
  @keyframes flipTop {
    0% {
      transform: rotateX(0deg);
      z-index: 2;
    }
    0%,
    99% {
      opacity: 1;
    }
    100% {
      transform: rotateX(-90deg);
      opacity: 0;
    }
  }
  @keyframes flipBottom {
    0%,
    50% {
      z-index: -1;
      transform: rotateX(90deg);
      opacity: 0;
    }
    51% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
      z-index: 5;
    }
  }
  /* flip-timer-css-end */
  /* media query start */
  @media screen and (max-width: 1400px) {
  .card {
    font-size: 5vw;
  }
  .clock-title h3 {
    margin-top: 20px;
  }
  }
  @media screen and (max-width: 968px) {
  .card {
    font-size: 7vw;
  }
  }
  @media screen and (max-width: 768px) {
  
  .card {
    font-size: 8vw;
  }
  header {
    max-width: 382px;
    margin: 0 auto;
    padding-top: 50px;
    padding-left: 0px;
  }
  .spider-bg {
    max-width: 1165px;
    height: 75%;
  }
  .clock-logo {
  max-width: 81px;
  }
  .flip-clock {
  margin: 5px auto;
  margin-block-end: 5px;
  }
  .clock-title h3 {
  margin-top: 0px;
  font-size: 20px;
  }
  .clock-title h3 {
  letter-spacing: 5px;
  }
  }
  @media screen and (max-width: 576px) {
  .spider-bg {
    max-width: 1100px;
  }
  .clock-logo {
  max-width: 70px;
  }
  header {
  padding-top: 100px;
  }
  .clock-title h3 {
  font-size: 25px;
  letter-spacing: 5px;
  }
  .card {
  font-size: 10vw;
  }
  }
  @media screen and (max-width: 420px) {
  .spider-bg {
    max-width: 1050px;
  }
  .flip-clock__slot {
  font-size: 16px;
  }
  .clock-logo {
  max-width: 70px;
  }
  header {
  max-width: 300px;
  }
  .card{
  margin-bottom: 5px;
  }
  .flip-clock__piece {
  display: inline-block;
  margin: 0 5px;
  }
  .clock-title h3 {
  margin-top: 15px;
  }
  
  }
  @media (min-width: 3360px) {
    .card { 
      font-size: 2vw;
    }
  }
  
  /* media query end*/