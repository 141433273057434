.refDetails {
    margin: 0 auto 80px;
    max-width: calc(100% - 30px);
}

.refDetailsList {
    border: 1px double #ffffff;
    padding: 0px;
    border-radius: 5px;
    position: relative;

    .table-responsive {
        overflow: auto;
    }

    table {
        min-width: 700px;

        thead {
            position: sticky;
            top: 0;
            position: -webkit-sticky;
            position: -moz-sticky;
            position: -ms-sticky;
            position: -o-sticky;
            z-index: 99;
            -webkit-position: sticky;
        }

        th {
            background-color: #3c3c3c;
            padding: 15px;
            font-weight: 500;
            font-size: 18px;

            &:nth-child(1) {
                border-radius: 6px 0px 0px 0px;
            }

            &:nth-last-child(1) {
                border-radius: 0px 6px 0px 0px;
            }
        }

        td {
            font-size: 16px;
            padding: 10px 15px;
            border-bottom: 1px solid #3c3c3c;
        }

        tr {
            &:nth-last-child(1) {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}

.myPagination {
    margin: 0 auto;
    margin-top: 20px;
    width: fit-content;
    background-color: #ffffff73;

    button.Mui-selected {
        background-color: #fcfcfcd1;
    }
}

.claimBonus {
    .buttonGrp {
        margin: 0 auto;
        text-align: center;

        button {
            background: #14141b !important;
            color: #ffffff;
            position: relative;
            border: none;

            &::before {
                content: "";
                position: absolute;
                top: -4px;
                left: -4px;
                height: calc(100% + 8px);
                width: calc(100% + 8px);
                background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
                border-radius: 6px;
                z-index: -1;
                animation: animatedgradient 3s ease alternate infinite;
                background-size: 300% 300%;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                height: calc(100% + 0px);
                width: calc(100% + 0px);
                background: #14141b;
                border-radius: 6px;
                z-index: -1;
            }
        }
    }
}


.MuiTabs-scroller .MuiTabs-flexContainer button.Mui-selected {
    background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
    background-size: 200%;
    border: 1px solid #000;
    border-radius: 5px;
    color: #231f20;
    font-weight: 600;
}

.MuiTabs-scroller .MuiTabs-flexContainer button {
    color: #fff;
    font-family: Inter, sans-serif;
    font-size: 16px;
    min-width: 90px;
}

.MuiTabs-indicator {
    display: none;
}

.hisDetailsList {
    border: 1px double #ffffff;
    padding: 0px;
    border-radius: 5px;
    position: relative;
    width: 650px;
    margin: 0 auto;
    text-align: center;
}

.hisDetailsList table th {
    background-color: #3c3c3c;
    padding: 15px;
    font-weight: 500;
    font-size: 18px;
}


.hisDetailsList table td {
    padding: 10px;
}

.height{
    min-height: 45vh;
}

@media (max-width: 700px) {
    .hisDetailsList {
        width: 100%;
        overflow-x: scroll;
        text-wrap: nowrap;
    }
}

@media (max-width: 575px) {
    .balanceOverviewBox {
        padding: 20px;
    }
}